import { GET_ADMIN_NOTIFICATION } from '../actions';

const initialState = {
    AdminNotification: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ADMIN_NOTIFICATION:
            return {
                ...state,
                AdminNotification: action.payload,
            };
        default:
            return state;
    }
}
