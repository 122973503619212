import { GET_ALL_SCHEDULE , GET_SCHEDULE_BY_ID } from "../actions";

const initialState = {
    Schedule: null,
    schedulebycompany: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_SCHEDULE:
            return {
                ...state,
                Schedule: action.payload,
            };

            case GET_SCHEDULE_BY_ID:
                return {
                    ...state,
                    schedulebycompany: action.payload,
                };
        
        default:
            return state;
    }
}
