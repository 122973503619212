import { GET_COINS_RANKS, GET_PERFORMANCE_HISTORY } from "../actions";

const initialState = {
    CoinRank: null,
    getPerformanceHistory: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COINS_RANKS:
            return {
                ...state,
                CoinRank: action.payload,
            };
        case GET_PERFORMANCE_HISTORY:
            return {
                ...state,
                getPerformanceHistory: action.payload,
            };

        default:
            return state;
    }
}