import dashboard from './dashboard';
import dashboardClient from './dashboardClient';
import IndividualClient from './IndividualClient';
import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //
const menuItems = {
    items: [dashboard, pages],
    client_items: [dashboardClient, pages],
    Individual_items: [IndividualClient, pages]
};

export default menuItems;
