import { GET_RANK_STATUS, GET_RANKS } from "../actions";

const initialState = {
  RankStatus: [],
  Ranks: {
    daily: true,
    week: false,
    monthly: false,
    total: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RANK_STATUS:
      return {
        ...state,
        RankStatus: action.payload,
      };

    case GET_RANKS:
      return {
        ...state,
        Ranks: action.payload,
      };

    default:
      return state;
  }
}
