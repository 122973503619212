import { GET_LOGIN_DATA, GET_FORGOT_DATA, GET_RELOAD } from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action";

export const login = (signInData, navigate) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.post("/adminuser/login", signInData)
    .then((res) => {
      localStorage.setItem("token", res.data.token);

      toast.success("Login successfully.", {
        duration: 1400
      });

      dispatch({
        type: GET_LOGIN_DATA,
        payload: res.data.user,
      });

      setTimeout(function () {
        dispatch(changeflag(false));
        if (res.data.user.isSuperadmin == true) {
          navigate("/super-admin", { replace: true });
        } else {
          navigate("/client", { replace: true });
        }
        navigate(0);
      }, 1500);
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.toJSON().message === "Network Error") {
        alert("No internet connection");
        navigate(0);
      }
      if (error.response) {
        toast.error(error?.response?.data?.message);

        if (error?.response?.data?.error) {
          if (error?.response?.data?.error == "email_not_exist") {
            if (signInData?.type) {
              setTimeout(function () {
                navigate("/register", { replace: true });
              }, 2000);
            }
          }
        }
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const register = (signInData, navigate) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/adminuser/register", signInData)
    .then((res) => {
      if (res.data.error == false) {
        if (signInData?.type) {
          localStorage.setItem("token", res.data.token);
          toast("Login successfully.");
          dispatch({
            type: GET_LOGIN_DATA,
            payload: res.data.user,
          });

          setTimeout(function () {
            if (res.data.user.isSuperadmin == true) {
              navigate("/super-admin", { replace: true });
            } else {
              navigate("/client", { replace: true });
            }
            navigate(0);
          }, 1000);
        } else {
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-0 p-4">
                <div className="flex items-start">
                  <div className="ml-3 flex-1">
                    <p className="text-sm font-medium text-gray-900">
                      {res.data.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </div>
            </div>
          ));
          // toast.success(res.data.message);
          navigate("/login");
        }
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.toJSON().message === "Network Error") {
        alert("No internet connection");
        navigate(0);
      }
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const forgot = (data, navigate) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.post("/adminuser/forgotpassword", data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        dispatch({
          type: GET_FORGOT_DATA,
          payload: data.email,
        });
        navigate("/resetpassword");
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.toJSON().message === "Network Error") {
        alert("No internet connection");
        navigate(0);
      }
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const resetpassword = (data, navigate) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.put("/adminuser/resetpassword", data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const changepassword = (data, navigate, path) => (dispatch) => {
  dispatch(changeflag(true));

  Axios.post("/adminuser/changepassword", data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      navigate(path);

      dispatch(changeflag(false));
    })
    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const authConfig = (navigate) => (dispatch) => {
  Axios.get("/adminuser/authenticate")
    .then((res) => {
      dispatch({
        type: GET_LOGIN_DATA,
        payload: res.data.user,
      });
    })
    .catch((err) => {
      if (err.response.status === 401) {
        console.log("Unauthorized");
        toast.error("Unauthorized");
      }
      console.log("ERR:", err);
    });
};

export const logout = (navigate, msg) => (dispatch) => {
  const confirmed = window.confirm(
    msg ? msg : "Are you sure you want to logout?"
  );
  if (confirmed) {
    dispatch(changeflag(true));
    Axios.get("/adminuser/logout")
      .then((res) => {
        localStorage.removeItem("token");
        dispatch({
          type: GET_LOGIN_DATA,
          payload: {},
        });
        toast(res.data.message, {
          style: {
            borderRadius: "10px",
            color: "#fff",
            background: "#FF0000",
          },
        });
        dispatch(changeflag(false));
        navigate("/login", { replace: true });
        navigate(0);
      })
      .catch((error) => {
        dispatch(changeflag(false));
        if (error.response) {
          toast.error(error?.response?.data?.message);
        } else if (error.request) {
          toast.error(error?.request);
        } else {
          toast.error(error?.message);
        }
      });
  }
};

export const GetUserByID = (ID) => (dispatch) => {
  Axios.get("/user/" + ID)
    .then((res) => {
      dispatch({
        type: GET_LOGIN_DATA,
        payload: res.data,
      });
      // }
    })
    .catch((error) => {
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

export const AuthUpdate = (data) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.put("/auth/update", data)
    .then((res) => {
      if (res.data.error == false) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      dispatch(changeflag(false));
    })
    .catch((error) => {
      console.log(error);
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};

// export const GoogleLogins = async (googleuser, navigate) => (dispatch) => {
//   dispatch(changeflag(true));
//   Axios.post("/adminuser/sociallogin", googleuser)
//     .then((res) => {
//       toast("Login successfully.");
//       dispatch({
//         type: GET_LOGIN_DATA,
//         payload: res.data.user,
//       });
//       dispatch(changeflag(false));

//       if (res.data.user.isSuperadmin == true) {
//         navigate('/super-admin', { replace: true });
//       } else {
//         navigate('/client', { replace: true });
//       }
//       navigate(0);

//     })
//     .catch((error) => {
//       dispatch(changeflag(false));

//       if (error.response) {
//         toast.error(error?.response?.data?.message);
//       } else if (error.request) {
//         toast.error(error?.request);
//       } else {
//         toast.error(error?.message);
//       }
//     });

// }
