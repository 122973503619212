import { GET_ALL_APPS_ENVIRONMENT , GET_ALL_APPS_OF_COMPANY } from "../actions";

const initialState = {
    AppsEnvironment: null,
    BlockedApp: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_APPS_ENVIRONMENT:
            return {
                ...state,
                AppsEnvironment: action.payload,
            };
        case GET_ALL_APPS_OF_COMPANY:
            return {
                ...state,
                BlockedApp: action.payload,
            };
            
        default:
            return state;
    }
}
