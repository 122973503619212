import { GET_CLIENT_NOTIFICATION } from '../actions';
import toast from 'react-hot-toast';
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action"

export const getClientNotification = (id) => (dispatch) => {
    dispatch(changeflag(true));
    Axios.get("/clientadminnotification/companyid/" + id)
        .then((res) => {
            dispatch({
                type: GET_CLIENT_NOTIFICATION,
                payload: res.data,
            });
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const UpdateClientNotification = (body, data) => (dispatch) => {
    Axios.put("/clientadminnotification/" + data._id, body)
        .then((res) => {
            if (res.data.error == false) {
                // toast.success(res.data.message);
                dispatch(getClientNotification(data.company_id));
            }
            else {
                toast.error(res.data.message);
            }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
}


export const ClientNotificationReadAll = (id) => (dispatch) => {
    Axios.put("/clientadminnotification/update/compid/" + id)
        .then((res) => {
            if (res.data.error == false) {
                dispatch(getClientNotification(id));
            }
            else {
                toast.error(res.data.message);
            }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
}