import { LOADER_STATUS,AUTHENTICATOR_STATUS } from "../actions";

export const changeflag = (flag) => (dispatch) => {
    dispatch({
        type: LOADER_STATUS,
        payload: flag,
      });

};
export const changeAuthentication= (flag) => (dispatch) => {
  dispatch({
      type: AUTHENTICATOR_STATUS,
      payload: flag,
    });

};