import {
    GET_ALL_USERS
    , GET_ALL_USERS_BY_COMPANY_ID
    , GET_ALL_USERS_BY_FILTER,
    GET_RANK_COINS
} from "../actions";

const initialState = {
    Users: null,
    CompanyUsers: null,
    RankCoins: null,
    RankCoinsTrophy: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                Users: action.payload,
            };
        case GET_ALL_USERS_BY_COMPANY_ID:
            return {
                ...state,
                CompanyUsers: action.payload,
            };
        case GET_RANK_COINS:
            return {
                ...state,
                RankCoins: action.payload,
                RankCoinsTrophy: action.payload2,
            };
        case GET_ALL_USERS_BY_FILTER:
            return {
                ...state,
                Filter: action.payload,
            };

        default:
            return state;
    }
}