import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
// routing
import Routes from "routes";
// defaultTheme
import themes from "themes";
// project imports
import NavigationScroll from "layout/NavigationScroll";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "./config";
import "utils/i18n";
import { GetLanuages } from "./store/language/language.action";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GOOGLE_CLIENT_ID } from "./utils/DataApi";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "./utils/i18n";

// ==============================|| APP ||============================== //

const App = () => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.AllLanguage);
  const [isAdmin, setIsAdmin] = useState(false);
  const customization = useSelector((state) => state.customization);
  const { paymentintent } = useSelector((state) => state?.paymentintent);

  const Selectedlang = (localStorage.getItem("i18nextLng") || "").split("-")[0];
  const stripePromise = loadStripe(config.Public_key);
  const [clientSecret, setClientSecret] = useState(
    paymentintent?.client_secret
  );

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  useEffect(() => {
    dispatch(GetLanuages());
  }, []);

  useEffect(() => {
    if (language) {
      let lang = "en";
      if (Selectedlang in language) {
        lang = Selectedlang;
      }

      i18n.changeLanguage(lang);
      localStorage.setItem("language", JSON.stringify(language));

      const resources = {
        en: {
          translation: language?.en?.translation || {},
        },
        es: {
          translation: language?.es?.translation || {},
        },
        pr: {
          translation: language?.pr?.translation || {},
        },
        pt: {
          translation: language?.pt?.translation || {},
        },
      };

      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources,
          lng: lang,
          fallbackLng: lang,
          interpolation: {
            escapeValue: false,
          },
          react: {
            wait: true,
          },
        });
    } else {
      dispatch(GetLanuages());
    }
  }, [language]);

  return (
    <Elements options={options} stripe={stripePromise}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <Routes />
            </GoogleOAuthProvider>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </Elements>
  );
};

export default App;
