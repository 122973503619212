import { GET_SUPER_ADMIN_DASHBOARD } from "../actions";

const initialState = {
    SuperAdmin: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SUPER_ADMIN_DASHBOARD:
            return {
                ...state,
                SuperAdmin: action.payload,
            };
        default:
            return state;
    }
}