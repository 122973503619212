// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    // title: 'Pages',
    // caption: 'Pages Caption',
    type: 'group',
    children: [
        // {
        //     id: 'metrics',
        //     title: 'Metrics',
        //     type: 'item',
        //     icon: icons.IconKey,
        //     url: '/utils/util-typography',
        //     breadcrumbs: false

        // },
        // {
        //     id: 'clients',
        //     title: 'Clients',
        //     type: 'item',
        //     icon: icons.IconKey,
        //     url: '/utils/util-typography',
        //     breadcrumbs: false

        // },
        // {
        //     id: 'clientsreport',
        //     title: 'Clients Report',
        //     type: 'item',
        //     icon: icons.IconKey,
        //     url: '/utils/util-typography',
        //     breadcrumbs: false

        // },
        // {
        //     id: 'schedule',
        //     title: 'Schedule',
        //     type: 'item',
        //     icon: icons.IconKey,
        //     url: '/utils/util-typography',
        //     breadcrumbs: false

        // },
        // {
        //     id: 'logout',
        //     title: 'Logout',
        //     type: 'item',
        //     icon: icons.IconKey,
        //     url: '',
        //     breadcrumbs: false
        // },
    ]

};

export default pages;
// children: [
            //     {
            //         id: 'login3',
            //         title: 'Login',
            //         type: 'item',
            //         url: '/pages/login/login3',
            //         target: true
            //     },
            //     {
            //         id: 'register3',
            //         title: 'Register',
            //         type: 'item',
            //         url: '/pages/register/register3',
            //         target: true
            //     }
            // ]