import React, { useState, useEffect } from "react";
import "./Spinner.css";
import Lottie from "react-lottie";
import animation from './../assets/loader.json'
import { useSelector } from 'react-redux';

const LoadingSpinner = () => {
    const { loader_status } = useSelector((state) => state.loader);
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(loader_status);
    }, [loader_status]);


    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <>
            {checked ?
                <div className="relative">
                    <div className="flex items-center justify-center h-[100vh]">
                        <Lottie options={defaultOptions} height={220} width={220} />
                    </div>
                </div>
                :
                <>
                </>
            }
        </>
    )
}
export default LoadingSpinner;