import { createRoot } from 'react-dom/client';
// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// project imports
import * as serviceWorker from 'serviceWorker';
import { store } from 'store';
import axios from "axios";
axios.defaults.withCredentials = true;
import AppLayout from "./checklogin/AppLayout";
// style + assets
import 'assets/scss/style.scss';
import './index.css'
import config from './config';
import { Toaster } from 'react-hot-toast';
import LoaderSpinner from './utils/Loader';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)



// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <BrowserRouter basename={config.basename}>
            <Toaster />
            <AppLayout />
        </BrowserRouter>
    </Provider>
);


serviceWorker.unregister();
