import { GET_LANGUAGE } from "../actions";
import toast from "react-hot-toast";
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action";

export const GetLanuages = (lng) => (dispatch) => {
  dispatch(changeflag(true));
  Axios.get("/common/getlanguages", {
    headers: {
      Language: lng
    }
  })
    .then((res) => {
      if (res.status == 200) {
        localStorage.setItem('language', JSON.stringify(res.data))
        dispatch({
          type: GET_LANGUAGE,
          payload: res.data,
        });
      }
      dispatch(changeflag(false));
      // return res.data;
    })

    .catch((error) => {
      dispatch(changeflag(false));
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error(error?.request);
      } else {
        toast.error(error?.message);
      }
    });
};
