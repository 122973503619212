import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardClient = Loadable(lazy(() => import('views/clientDashboard/index')))


//Not Found
const PageNotfound = Loadable(lazy(() => import('views/utilities/PageNotFound')))

// utilities routing
const UtilsMetrics = Loadable(lazy(() => import('views/utilities/Metrics')));
const UtilsClients = Loadable(lazy(() => import('views/utilities/Clients')));
const UtilsClientsReport = Loadable(lazy(() => import('views/utilities/ClientsReport')));
const UtilsSchedule = Loadable(lazy(() => import('views/utilities/Schedule')));
const RoadMap = Loadable(lazy(() => import('views/utilities/RoadMap')));
const SuperAppsEnvironment = Loadable(lazy(() => import('views/utilities/AppsEnvironment')));



//  User routing
const UserMetrics = Loadable(lazy(() => import('views/pages/Metrics/UserMetrics')));
const UserDetail = Loadable(lazy(() => import('views/pages/Metrics/UserDetail')));
const AllowedApps = Loadable(lazy(() => import('views/pages/Metrics/AllowedApps')));
const BlockedApps = Loadable(lazy(() => import('views/pages/Metrics/BlockedApps')));
const DistractedApps = Loadable(lazy(() => import('views/pages/Metrics/DistractedApps')));

//  Company routing
const AllCompanyDetails = Loadable(lazy(() => import('views/pages/Metrics/AllCompanyDetails')));
const CompanyDetails = Loadable(lazy(() => import('views/pages/Metrics/CompanyDetails')));
const CompanyAllowedApps = Loadable(lazy(() => import('views/pages/Metrics/CompanyAllowedApps')));
const CompanyBlockedApps = Loadable(lazy(() => import('views/pages/Metrics/CompanyBlockedApps')));
const CompanyDistractedApps = Loadable(lazy(() => import('views/pages/Metrics/CompanyDistractedApps')));

//  Company routing
const AddClients = Loadable(lazy(() => import('views/pages/clients/AddClients')));
const AddClientsSchedule = Loadable(lazy(() => import('views/pages/clients/AddClientsSchedule')));
const AppsEnvironment = Loadable(lazy(() => import('views/pages/clients/AppsEnvironment')));

const AddRoadMap = Loadable(lazy(() => import('views/pages/RoadMap/AddRoadMap')));
const AddApps = Loadable(lazy(() => import('views/pages/SuperApps/AddApps')));



// changePassword routing
const ChangePassword = Loadable(lazy(() => import('views/pages/changePassword/ChangePassword')));

//Client Report routes
const UsersReport = Loadable(lazy(() => import('views/pages/UserReport/UsersReport')));
const Invite = Loadable(lazy(() => import('views/pages/invite/Invite')));
const UserListing = Loadable(lazy(() => import('views/pages/Users/UserListing')));
const AddUsers = Loadable(lazy(() => import('views/pages/Users/AddUsers')));
const UserProfile = Loadable(lazy(() => import('views/pages/profile/UserProfile')))

//View All Notifications
const AllNotification = Loadable(lazy(() => import('layout/MainLayout/Header/NotificationSection/AllNotification')))

//Payment Plan
const PaymentPlanListing = Loadable(lazy(() => import('views/pages/PaymentPlan/PaymentPlanListing')))

//Subscription History
const SubscriptionHistory = Loadable(
    lazy(() => import("views/pages/subscription_history/History"))
  );
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '',
            children: [
                {
                    path: 'super-admin',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: '',
            children: [
                {
                    path: 'client',
                    element: <DashboardClient />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'metrics',
                    element: <UtilsMetrics />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'clients',
                    element: <UtilsClients />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'clientreport',
                    element: <UtilsClientsReport />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'users',
                    element: <UserListing />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'user-report',
                    element: <UsersReport />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'schedule',
                    element: <UtilsSchedule />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'road_map',
                    element: <RoadMap />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'payment_listing',
                    element: <PaymentPlanListing />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'apps-environment',
                    element: <SuperAppsEnvironment />
                }
            ]
        },
        {
            path: 'invite',
            children: [
                {
                    path: 'user',
                    element: <Invite />
                }
            ]
        },
        {
            path: "subscription",
            children: [
              {
                path: "history",
                element: <SubscriptionHistory />,
              },
            ],
          },

        {
            path: '/metrics/user-metrics',
            element: <UserMetrics />
        },
        {
            path: '/metrics/user-detail',
            element: <UserDetail />
        },
        {
            path: '/user-detail/allowed-apps',
            element: <AllowedApps />
        },
        {
            path: '/user-detail/blocked-apps',
            element: <BlockedApps />
        },
        {
            path: '/user-detail/distracted-apps',
            element: <DistractedApps />
        },
        {
            path: '/metrics/company-metrics',
            element: <AllCompanyDetails />
        },
        {
            path: '/metrics/company-details',
            element: <CompanyDetails />
        },
        {
            path: '/company-details/company-allowed-apps',
            element: <CompanyAllowedApps />
        },
        {
            path: '/company-details/company-blocked-apps',
            element: <CompanyBlockedApps />
        },
        {
            path: '/company-details/company-distracted-apps',
            element: <CompanyDistractedApps />
        },
        {
            path: '/clients/add-clients',
            element: <AddClients />
        },
        {
            path: '/add-clients/add-schedule',
            element: <AddClientsSchedule />
        },
        {
            path: '/add-schedule/apps-environment',
            element: <AppsEnvironment />
        },
        {
            path: '/change-password',
            element: <ChangePassword />
        },
        {
            path: '/change-password',
            element: <ChangePassword />
        },
        {
            path: '/users/add-user',
            element: <AddUsers />
        },
        {
            path: '/users/profile',
            element: <UserProfile />
        },
        {
            path: '/view/notification',
            element: <AllNotification />
        },
        {
            path: '/road_map/add',
            element: <AddRoadMap />
        },
        {
            path: '/apps_env/add',
            element: <AddApps />
        },

        {
            path: '*',
            element: <PageNotfound />
        },

    ],
};

export default MainRoutes;
