import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const locallanguage = JSON.parse(localStorage.getItem("language"));
const resources = {
  en: {
    translation: locallanguage?.en?.translation || {},
  },
  es: {
    translation: locallanguage?.es?.translation || {},
  },
  pr: {
    translation: locallanguage?.pr?.translation || {},
  },
  pt: {
    translation: locallanguage?.pt?.translation || {},
  },
};

const langg = (localStorage.getItem("i18nextLng") || "").split("-")[0];
const Selectedlang = langg in resources ? langg : "en";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: Selectedlang,
    fallbackLng: Selectedlang,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  });

export default i18n;
