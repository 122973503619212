import { GET_ADMIN_NOTIFICATION } from '../actions';
import toast from 'react-hot-toast';
import Axios from "../../utils/Axios";
import { changeflag } from "../loader/loader.action"

export const getAllNotification = () => (dispatch) => {
    dispatch(changeflag(true));
    Axios.get("/superadminnotification")
        .then((res) => {
            dispatch({
                type: GET_ADMIN_NOTIFICATION,
                payload: res.data,
            });
            dispatch(changeflag(false));
        })
        .catch((error) => {
            dispatch(changeflag(false));
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
};

export const UpdateSuperAdminNotification = (body, data) => (dispatch) => {
    Axios.put("/superadminnotification/" + data._id, body)
        .then((res) => {
            if (res.data.error == false) {
                // toast.success(res.data.message);
                dispatch(getAllNotification());
            }
            else {
                toast.error(res.data.message);
            }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
}

export const AdminNotificationReadAll = () => (dispatch) => {
    Axios.put("/superadminnotification/update/change/")
        .then((res) => {
            if (res.data.error == false) {
                dispatch(getAllNotification());
            }
            else {
                toast.error(res.data.message);
            }
        })
        .catch((error) => {
            if (error.response) {
                toast.error(error?.response?.data?.message);
            } else if (error.request) {
                toast.error(error?.request);
            } else {
                toast.error(error?.message);
            }
        });
}