import { GET_ALL_ROADMAP } from "../actions";

const initialState = {
    RoadMap: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_ROADMAP:
            return {
                ...state,
                RoadMap: action.payload,
            };

        default:
            return state;
    }
}
