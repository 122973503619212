import { GET_CLIENT_ADMIN_DASHBOARD } from "../actions";

const initialState = {
    ClientAdmin: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CLIENT_ADMIN_DASHBOARD:
            return {
                ...state,
                ClientAdmin: action.payload,
            };
        default:
            return state;
    }
}