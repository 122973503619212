import { GET_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_ALL_HISTORY, GET_SUBSCRIPTION_HISTORY_BY_ID } from "../actions";

const initialState = {
    subscription_plan: null,
    history: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SUBSCRIPTION_PLAN:
            return {
                ...state,
                subscription_plan: action.payload,
            };
        case GET_SUBSCRIPTION_ALL_HISTORY:
            return {
                ...state,
                history: action.payload,
            };
        case GET_SUBSCRIPTION_HISTORY_BY_ID:
            return {
                ...state,
                history: action.payload,
            };
        default:
            return state;
    }
}
