import { GET_ALL_OPEN_APPS } from "../actions";

const initialState = {
    OpenApps: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_OPEN_APPS:
            return {
                ...state,
                OpenApps: action.payload,
            };

        default:
            return state;
    }
}
