import { GET_PAYMENT_PLAN ,PAYMENT_METHOD_SETTING} from "../actions";

const initialState = {
    Plans: null,
    Plan_Setting:null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_PLAN:
            return {
                ...state,
                Plans: action.payload,
            };
            case PAYMENT_METHOD_SETTING:
                return {
                    ...state,
                    Plan_Setting: action.payload,
                };
        default:
            return state;
    }
}