import { GET_RELOAD } from "../actions";

const initialState = {
  Reload: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RELOAD:
      return {
        ...state,
        Reload: action.payload,
      };

    default:
      return state;
  }
}
