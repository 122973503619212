import axios from "axios";
axios.defaults.withCredentials = true;

import { LOCALHOST_BASE_URL, BASE_URL } from "./DataApi";
let token = localStorage.getItem("token");
let url;
if (window.location.hostname === 'localhost') {
  url = LOCALHOST_BASE_URL;
} else {
  url = BASE_URL;
}

const instance = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    "x-access-token": token,
  },
});

// res.setHeader("Access-Control-Allow-Headers", "*");

export default instance;
