import { GET_ALL_COMPANY , GET_ALL_COMPANY_Filter } from "../actions";

const initialState = {
    Company: null,
    Filter: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_COMPANY:
            return {
                ...state,
                Company: action.payload,
            };
            case GET_ALL_COMPANY_Filter:
                return {
                    ...state,
                    Filter: action.payload,
                };

        default:
            return state;
    }
}
