// assets
import { FaRegChartBar } from 'react-icons/fa';
import { ImUsers, ImProfile } from 'react-icons/im';
import { GrSchedule, GrAppsRounded } from 'react-icons/gr';
import { MdLogout, MdOutlinePayments } from 'react-icons/md';
import { RiDashboard3Line } from 'react-icons/ri';
import { SiGumroad } from 'react-icons/si';
import { AiOutlineTransaction,AiFillAppstore  } from "react-icons/ai";
import i18next from '../utils/i18n'

// constant
const icons = {AiOutlineTransaction,RiDashboard3Line, FaRegChartBar, ImUsers, ImProfile, GrSchedule, MdLogout, SiGumroad, GrAppsRounded, MdOutlinePayments,AiFillAppstore  };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/super-admin',
            icon: icons.RiDashboard3Line,
            breadcrumbs: false
        },
        {
            id: 'metrics',
            title:'Metrics',
            type: 'item',
            icon: icons.FaRegChartBar,
            url: '/utils/metrics',
            breadcrumbs: false
        },
        {
            id: 'clients',
            title: 'Clients',
            type: 'item',
            icon: icons.ImUsers,
            url: '/utils/clients',
            breadcrumbs: false

        },
        {
            id: 'clientsreport',
            title: 'Clients Report',
            type: 'item',
            icon: icons.ImProfile,
            url: '/utils/clientreport',
            breadcrumbs: false

        },
        {
            id: 'roadmap',
            title: 'Road Map',
            type: 'item',
            icon: icons.SiGumroad,
            url: '/utils/road_map',
            breadcrumbs: false

        },
        {
            id: 'appsenv',
            title: 'global apps',
            type: 'item',
            icon: icons.AiFillAppstore,
            url: '/utils/apps-environment',
            breadcrumbs: false

        },
        {
            id: "subscription",
            title: "Subscription History",
            type: "item",
            url: "/subscription/history",
            icon: icons.AiOutlineTransaction,
            breadcrumbs: false,
          },
        // {
        //     id: 'paymentplan',
        //     title: 'Add Payment Plans',
        //     type: 'item',
        //     icon: icons.MdOutlinePayments,
        //     url: '/utils/payment_listing',
        //     breadcrumbs: false

        // },
        {
            id: 'logout',
            title: 'logout',
            type: 'item',
            icon: icons.MdLogout,
            url: '',
            breadcrumbs: false,
        },

    ]
};

export default dashboard;
