import { GET_LANGUAGE } from "../actions";

const initialState = {
    language: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
      

        default:
            return state;
    }
}