import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClientNotificationReadAll } from "../../../../store/ClientNotification/ClientNotfication.action";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CardActions,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  InputLabelBadge,
  Badge,
} from "@mui/material";
// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import NotificationList from "./NotificationList";
// assets
import { IconBell } from "@tabler/icons";
//Redux
import {
  getAllNotification,
  AdminNotificationReadAll,
} from "../../../../store/SuperAdminNotification/AdminNotfication.action";
import { getClientNotification } from "../../../../store/ClientNotification/ClientNotfication.action";
import { useSelector, useDispatch } from "react-redux";
import ReactTimeAgo from "react-time-ago";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
  const dispatch = useDispatch();
  const { login_Session } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const { AdminNotification } = useSelector(
    (state) => state?.AdminNotification
  );
  const { ClientNotification } = useSelector(
    (state) => state?.ClientNotification
  );

  const admincount = AdminNotification?.data?.length;
  const clientcount = ClientNotification?.data?.length;

  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleChange = (event) => {
    if (event?.target.value) setValue(event?.target.value);
  };

  useEffect(() => {
    if (login_Session?.isSuperadmin == true) {
      dispatch(getAllNotification());
    } else {
      dispatch(getClientNotification(login_Session?._id));
    }
  }, [dispatch]);

  const MarkAsAllRead = (e) => {
    if (login_Session?.isSuperadmin == true) {
      dispatch(AdminNotificationReadAll());
    } else {
      dispatch(ClientNotificationReadAll(login_Session?._id));
    }
  };

  return (
    <>
      <Box
        sx={{
          ml: 2,
          mr: 3,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <ButtonBase sx={{ borderRadius: "12px" }}>
          <Avatar
            className="!overflow-visible"
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.light,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            color="inherit"
          >
            <Badge
              color="secondary"
              badgeContent={
                login_Session?.isSuperadmin == true
                  ? AdminNotification?.counter
                  : ClientNotification?.counter
              }
              max={99}
            >
              <IconBell stroke={1.5} size="1.3rem" />
            </Badge>
          </Avatar>
        </ButtonBase>
      </Box>

      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? 5 : 0, 20],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            position={matchesXs ? "top" : "top-right"}
            in={open}
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        alignItems="center"
                        spacing={3}
                        justifyContent="space-between"
                        sx={{ pt: 2, px: 2 }}
                      >
                        <Grid item>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="subtitle1">
                              {t("all_notification")}
                            </Typography>
                            <Chip
                              size="small"
                              label={
                                login_Session.isSuperadmin == true
                                  ? admincount
                                  : clientcount
                              }
                              sx={{
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark,
                              }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item>
                          <Button
                            color="primary"
                            disabled={
                              login_Session.isSuperadmin == false &&
                              ClientNotification?.data?.length == 0
                                ? true
                                : false
                            }
                            onClick={(e) => {
                              MarkAsAllRead(e);
                              setOpen(false);
                            }}
                          >
                            {t("mark_all_read")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        style={{
                          height: "100%",
                          maxHeight: "calc(100vh - 205px)",
                          overflowX: "auto",
                        }}
                      >
                        <NotificationList />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardActions sx={{ p: 1.25, justifyContent: "center" }}>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      disabled={
                        login_Session.isSuperadmin == false &&
                        ClientNotification?.data?.length == 0
                          ? true
                          : false
                      }
                      component={Link}
                      to="/view/notification"
                      size="small"
                      disableElevation
                    >
                      {t("view_all")}
                    </Button>
                  </CardActions>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
